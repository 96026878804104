import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function NotificationPopup() {
  const [notifications, setNotifications] = useState([]);
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const user = userMetadata;
  const token = apiAccessToken;
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!user?.id || !token) return;

      const URL = `https://usell.be/api/notifications/unread/${user.id}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setNotifications(data.unread_notifications);
      } catch (error) {
        console.error("Error fetching unread notifications:", error);
      }
    };

    fetchNotifications();
  }, [token, user]);

  const markAsRead = async (notificationId) => {
    const URL = `https://usell.be/api/notifications/read/${notificationId}/`;
    const options = {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) throw new Error("Failed to mark notification as read");
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== notificationId)
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  if (!showPopup || !notifications.length) return null;

  return (
    <motion.div
      className="popup-container"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.3 }}
    >
      <div className="popup-content">
        <h4>{t("unread_notifications")}</h4>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className="notification-item"
              style={{ marginBottom: "8px" }}
            >
              <p style={{ margin: 0 }}>
                {notification.message} <br />
                <small>
                  {new Date(notification.timestamp).toLocaleString()}
                </small>
              </p>
              <Button
                variant="primary"
                size="sm"
                onClick={() => markAsRead(notification.id)}
                style={{ marginTop: "4px" }}
              >
                {t("mark_as_read")}
              </Button>
            </li>
          ))}
        </ul>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{ marginTop: "12px" }}
        >
          {t("close")}
        </Button>
      </div>
    </motion.div>
  );
}

export default NotificationPopup;
