import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const CarDetailModal = ({ show, handleClose, car }) => {
  if (!car) return null;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Auto Specificaties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <Row>
            <Col md={6}>
              <h4>Merk:</h4>
              <p>{car.brand_name}</p>

              <h4>Model:</h4>
              <p>{car.model}</p>

              <h4>Bouwjaar:</h4>
              <p>{car.build_year}</p>

              <h4>Kilometers:</h4>
              <p>{car.kilometers} km</p>

              <h4>Brandstof:</h4>
              <p>{car.fuel_type}</p>

              <h4>Transmissie:</h4>
              <p>{car.transmission}</p>
            </Col>
            <Col md={6}>
              <h4>Aandrijving:</h4>
              <p>{car.drive}</p>

              <h4>Aantal deuren:</h4>
              <p>{car.number_of_doors}</p>

              <h4>Kleur:</h4>
              <p>{car.color}</p>

              <h4>Chassisnummer:</h4>
              <p>{car.chassis_number}</p>

              <h4>Car BTW:</h4>
              <p>{car.car_btw ? car.car_btw : "Geen Car BTW beschikbaar"}</p>

              <h4>Documenten:</h4>
              <ul>
                <li>
                  {car.boorddocument_url ? (
                    <a
                      href={car.boorddocument_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gelijkvormigheidsattest
                    </a>
                  ) : (
                    "Gelijkvormigheidsattest"
                  )}
                  :{" "}
                  {car.boorddocument_url ? (
                    <a
                      href={car.boorddocument_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "green" }}
                      />
                    </a>
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ color: "red" }}
                    />
                  )}
                </li>
                <li>
                  {car.kenteken_url ? (
                    <a
                      href={car.kenteken_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Inschrijvingsbewijs
                    </a>
                  ) : (
                    "Inschrijvingsbewijs"
                  )}
                  :{" "}
                  {car.kenteken_url ? (
                    <a
                      href={car.kenteken_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "green" }}
                      />
                    </a>
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ color: "red" }}
                    />
                  )}
                </li>
                <li>
                  {car.kenteken2_url ? (
                    <a
                      href={car.kenteken2_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Keuringsattest
                    </a>
                  ) : (
                    "Keuringsattest"
                  )}
                  :{" "}
                  {car.kenteken2_url ? (
                    <a
                      href={car.kenteken2_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "green" }}
                      />
                    </a>
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ color: "red" }}
                    />
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </motion.div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CarDetailModal;
