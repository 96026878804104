import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import React, { useContext, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useSpring, animated } from "@react-spring/web";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useTranslation } from 'react-i18next';
import registerAnimation from "../lotties/register.json";
import carAnimation from "../lotties/car.json";
import { CarCard } from "../components/CarCard";
import { AuthContext } from '../contexts/AuthContext';

export function IndexPage(props) {

    const { loginWithRedirect, userMetadata, apiAccessToken } = useContext(AuthContext);
    const [paidAuctions, setPaidAuctions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPaidAuctions = async () => {
            if (!userMetadata || !apiAccessToken) return;

            const URL = "https://usell.be/api/paid-auctions/";
            const token = apiAccessToken;
            const options = {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
            };

            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
                const data = await response.json();
                setPaidAuctions(data);
            } catch (error) {
                console.error("Error fetching paid auctions:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPaidAuctions();
    }, [userMetadata, apiAccessToken]);

    const fadeInLeft = useSpring({ opacity: 1, transform: 'translateX(0)', from: { opacity: 0, transform: 'translateX(-20px)' } });
    const fadeInRight = useSpring({ opacity: 1, transform: 'translateX(0)', from: { opacity: 0, transform: 'translateX(20px)' } });
    const scaleUp = useSpring({ transform: 'scale(1)', from: { transform: 'scale(0.9)' } });

    const bounce1 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-10px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 0,
    });

    const bounce2 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-10px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 200,
    });

    const bounce3 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-10px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 400,
    });

    const bounce4 = useSpring({
        to: { transform: 'translateY(0px)' },
        from: { transform: 'translateY(-10px)' },
        config: { tension: 170, friction: 5 },
        reset: true,
        loop: { reverse: true },
        delay: 600,
    });

    return (
        <>
            <motion.div
                className="hero"
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeOut" }}
                viewport={{ once: true }}
                style={{
                    background: "linear-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url('images/index-hero.jpg') no-repeat fixed 50%",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.4)"
                }}
            >
                <Container>
                    <h1><span className="usell-u">U</span>SELL</h1>
                    <h2>{t('hero_subtitle')}</h2>
                    <animated.button style={bounce3} className="join-button animated-button" onClick={loginWithRedirect}>
                        {t('become_member')}
                    </animated.button>
                </Container>
            </motion.div>

            <Container className="content indexText">
                <Row>
                    <Col lg={5}>
                        <animated.div style={fadeInLeft}>
                            <h3>{t('about_pre')} <span className="usell-u smaller">U</span>SELL?</h3>
                            <p>{t('about_text')}</p>
                        </animated.div>
                    </Col>
                    <Col lg={7}>
                        <motion.video
                            controls
                            preload="metadata"
                            className="video-player vertical-center"
                            initial={{ opacity: 0, x: 50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
                            viewport={{ once: true }}
                        >
                            <source src="videos/about-nl.mp4#t=0.8" type="video/mp4" />
                        </motion.video>
                    </Col>
                </Row>
            </Container>

            <Container className="content">
                <Row>
                    <Col as={Link} onClick={loginWithRedirect} lg={6}>
                        <animated.div
                            className="register register-buyer indexPage-content hover-card"
                            style={{ ...scaleUp, ...bounce2 }}
                        >
                            <svg stroke={"currentColor"} fill={"currentColor"} strokeWidth={"0"} viewBox={"0 0 512 512"}
                                color={"rgba(255,255,255,0.5)"} height={"100px"}
                                width={"100px"} xmlns={"http://www.w3.org/2000/svg"}
                                style={{ color: "rgba(255, 255, 255, 0.5)" }}></svg>
                            <div className="vertical-center text-center register-content">
                                <h5>{t('register_buyer_title')}</h5>
                                <p>{t('register_buyer_text')}</p>
                            </div>
                        </animated.div>
                    </Col>
                    <Col as={Link} onClick={loginWithRedirect} lg={6}>
                        <animated.div
                            className="register register-seller indexPage-content hover-card"
                            style={{ ...scaleUp, ...bounce3 }}
                        >
                            <svg stroke={"currentColor"} fill={"currentColor"} strokeWidth={"0"} viewBox={"0 0 512 512"}
                                color={"rgba(255,255,255,0.5)"} className={"absolute top-0 left-0"} height={"100px"}
                                width={"100px"} xmlns={"http://www.w3.org/2000/svg"}
                                style={{ color: "rgba(255, 255, 255, 0.5)" }}></svg>
                            <div className="vertical-center text-center register-content">
                                <h5>{t('register_seller_title')}</h5>
                                <p>{t('register_seller_text')}</p>
                            </div>
                        </animated.div>
                    </Col>
                </Row>
            </Container>

            <div className={"bg-main"}>
                <Container className={"content"}>
                    <Row>
                        <Col lg={7} className="indexText">
                            <animated.div style={{ ...fadeInLeft, ...bounce4 }}>
                                <div className={"our-mission"}>
                                    <img src="/images/mission.png" className={"mission-logo"} alt="onze missie" />
                                    <h5>{t('mission_title')}</h5>
                                    <p>{t('mission_text')}</p>
                                </div>
                            </animated.div>
                        </Col>
                    </Row>
                </Container>
                <div className="section-divider"></div>
                <Container className={"content"}>
                    <Row className={"mt-4"}>
                        <Col lg={5}></Col>
                        <Col lg={7}>
                            <animated.div style={{ ...fadeInRight, ...bounce3 }}>
                                <div className={"our-mission text-end content-padding-bottom"}>
                                    <img src="/images/vision.png" className={"mission-logo"} alt="onze visie" />
                                    <h5>{t('vision_title')}</h5>
                                    <p>{t('vision_text')}</p>
                                </div>
                            </animated.div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className="content content-padding-bottom">
                <h3 className="text-center">{t('sold_auctions_title')}</h3>
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                ) : paidAuctions.length > 0 ? (
                    <Row>
                        {paidAuctions.map((auction) => (
                            <Col lg={4} md={6} className="mb-4" key={auction.id}>
                                <CarCard
                                    title={`${auction.car.brand_name} ${auction.car.model}`}
                                    buildyear={auction.car.build_year}
                                    mileage={auction.car.kilometers}
                                    fuelType={auction.car.fuel_type}
                                    image={auction.image_url || "/images/cars/opel.webp"}
                                    auctionId={auction.id}
                                />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <p className="index-paid-text">{t('no_paid_auctions')}</p>
                )}
            </Container>

            <div className={"bg-main"}>
                <Container className={"content"}>
                    <Row>
                        <Col lg={6}>
                            <Lottie animationData={registerAnimation} loop={true} />
                        </Col>
                        <Col lg={6} className={"vertical-center text-center"}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8, ease: "easeOut" }}
                                viewport={{ once: true }}
                                style={{ textShadow: "0px 0px 10px rgba(0, 0, 0, 1)" }}
                            >
                                <h3 className={"after-icon"}>{t('create_account_title')}</h3>
                                <p>{t('create_account_text1')}<br />
                                    {t('create_account_text2')}<br />
                                    <strong>{t('create_account_text3')}</strong></p>
                            </motion.div>
                        </Col>
                    </Row>
                </Container>
                <div className="section-divider"></div>
                <Container className={"content-padding-bottom"}>
                    <Row>
                        <Col lg={6} className={"vertical-center text-center"}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
                                viewport={{ once: true }}
                                style={{ textShadow: "0px 0px 10px rgba(0, 0, 0, 1)" }}
                            >
                                <h3>{t('auctions_title')}</h3>
                                <p>{t('auctions_text1')}<br /><br />{t('auctions_text2')}<br /><br />
                                    <strong>{t('auctions_text3')}</strong></p>
                            </motion.div>
                        </Col>
                        <Col lg={6} className={"mx-auto"}>
                            <Lottie animationData={carAnimation} loop={true} style={{ transform: "scale(1.3)" }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}